import React from "react"

interface FunkyHrProps {
  classes?: string
}

export function FunkyHr({ classes = "" }: FunkyHrProps) {
  return <hr className={`${classes} border-t-2 border-primary-500`} />
}

// #TODO la funky slackline...
// const vars = {
//   totalPoints: 6,
//   viscosity: 20,
//   mouseDist: 80,
//   damping: 0.15,
//   showIndicators: false,
//   leftColor: "#a8d0e6",
//   rightColor: "#f76c6c",
// }
