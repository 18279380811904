// extracted by mini-css-extract-plugin
export var addToCartStyle = "product-page-module--addToCartStyle--4743d";
export var header = "product-page-module--header--b0d16";
export var noImagePreview = "product-page-module--noImagePreview--0a179";
export var optionsWrapper = "product-page-module--optionsWrapper--d7e01";
export var priceValue = "product-page-module--priceValue--e1354";
export var productBox = "product-page-module--productBox--3671b";
export var productDescription = "product-page-module--productDescription--8e471";
export var productImageList = "product-page-module--productImageList--ce93e";
export var productImageListItem = "product-page-module--productImageListItem--36d96";
export var productImageWrapper = "product-page-module--productImageWrapper--0f6ba";
export var scrollForMore = "product-page-module--scrollForMore--90ff6";
export var selectVariant = "product-page-module--selectVariant--baef9";
export var visuallyHidden = "product-page-module--visuallyHidden--a0592";