import * as React from "react"
import { graphql, Link } from "gatsby"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../context/store-context"
import { AddToCart } from "../../components/add-to-cart"
import { NumericInput } from "../../components/numeric-input"
import { formatPrice } from "../../utils/format-price"
import { MostPopularKits } from "../../components/most-popular-kits"
import { Seo } from "../../components/seo"
import { FunkyHr } from "../../components/funky-hr"
import { CheckoutButton } from "../../components/checkout-button"

import {
  productBox,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  productDescription,
  // labelFont,
  // breadcrumb,
  // tagList,
  addToCartStyle,
  // metaSection,
} from "./product-page.module.css"

export default function Product(props) {
  const {
    data, // suggestions
    pageContext,
  } = props
  const { i18n, lang } = pageContext
  const { product, productI18n } = data

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    handle,
    media: images,
    media: [firstImage],
  } = product

  const { client, checkout, loading } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const cartQuantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const compareAtPrice = variant.compareAtPrice
    ? formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        variant.compareAtPrice
      )
    : null

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = false // ça marche mal leur osti de scrollbar horizontal // true || images.length > 1

  return (
    <>
      {firstImage ? (
        <Seo
          lang={lang}
          title={title}
          description={
            productI18n.nodes[0] ? productI18n.nodes[0].description : ""
          }
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <div className="mt-4 mb-20 md:mt-20 out-wrapper">
        <div className={`${productBox} grid mb-20`}>
          {hasImages && (
            <div className={`sm:min-w-[20rem] ${productImageWrapper}`}>
              <div
                role="group"
                aria-label="gallery"
                aria-describedby="instructions"
              >
                <ul className={productImageList}>
                  {[firstImage].map(({ image }, index) => (
                    <li
                      key={`product-image-${index}`}
                      className={productImageListItem}
                    >
                      {/* Custom hardcoded image 
                       {handle === "1-25mm-slackline-kits" ? ( 
                         i18n.common.currentLang === "en" ? ( 
                           <StaticImage 
                             src={`../../assets/images/prim_25_en.jpg`} 
                             alt="" 
                           /> 
                         ) : ( 
                           <StaticImage 
                             src="../../assets/images/prim_25_fr.jpg" 
                             alt="" 
                           /> 
                         ) 
                       ) : ( */}
                        <GatsbyImage
                          objectFit="contain"
                          loading={index === 0 ? "eager" : "lazy"}
                          alt={
                            image.altText
                              ? image.altText
                              : `Product Image of ${title} #${index + 1}`
                          }
                          image={image.gatsbyImageData}
                        />
                      {/* )} */}
                    </li>
                  ))}
                </ul>
              </div>
              {hasMultipleImages && (
                <div className={scrollForMore} id="instructions">
                  <span aria-hidden="true">←</span> scroll for more{" "}
                  <span aria-hidden="true">→</span>
                </div>
              )}
            </div>
          )}
          {!hasImages && (
            <span className={noImagePreview}>No Preview image</span>
          )}
          <div>
            {/* <div className={breadcrumb}>
              <Link to={product.handle}>{product.productType}</Link>
              <ChevronIcon size={12} />
            </div> */}
            <h1 className={header}>{title}</h1>
            {productI18n.nodes[0] && (
              <p
                className={productDescription}
                dangerouslySetInnerHTML={{
                  __html: productI18n.nodes[0].descriptionHtml,
                }}
              ></p>
            )}
            <h2 className={priceValue}>
              <span>{price}</span>
              {compareAtPrice && (
                <p className="text-sm text-red-400 line-through">
                  {compareAtPrice}
                </p>
              )}
            </h2>
            <fieldset className={optionsWrapper}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className={selectVariant} key={id}>
                    <select
                      aria-label="Variants"
                      onChange={(event) => handleOptionChange(index, event)}
                    >
                      <option value="">{`Select ${name}`}</option>
                      {values.map((value) => (
                        <option value={value} key={`${name}-${value}`}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
            </fieldset>
            <div className={addToCartStyle}>
              <NumericInput
                aria-label="Quantity"
                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                onChange={(event) => setQuantity(event.currentTarget.value)}
                value={quantity}
                min="1"
                max="50"
              />
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                i18n={i18n}
              />
            </div>
            <div className="flex flex-wrap gap-4 mt-8 xs:flex-nowrap">
              {cartQuantity > 0 ? (
                <>
                  <Link
                    className="max-w-sm flex items-center px-8 py-3 mx-auto text-xl font-bold text-center text-white !no-underline transition-colors rounded hover:bg-primary-500 bg-primary-600"
                    to={i18n.slugs.cart}
                  >
                    {i18n.common.viewCart}
                  </Link>
                  <CheckoutButton
                    loading={loading}
                    checkout={checkout}
                    i18n={i18n}
                    className="px-8 py-3 text-xl"
                  />
                </>
              ) : (
                <br />
              )}
            </div>
            {/* <div className={metaSection}>
              <span className={labelFont}>Tags</span>
              <span className={tagList}>
                {product.tags.map((tag) => (
                  <Link key={tag} to={`/#TODO?#${tag}`}>
                    {tag}
                  </Link>
                ))}
              </span>
            </div> */}
          </div>
        </div>

        <div className="grid gap-4 mb-32 md:grid-cols-2">
          {images.slice(1).map((item, index) => {
            return (
              <div key={index}>
                <GatsbyImage
                  objectFit="contain"
                  className="object-cover w-full h-full"
                  loading={index === 0 ? "eager" : "lazy"}
                  alt={
                    item.image.altText
                      ? item.image.altText
                      : `Product Image of ${title} #${index + 1}`
                  }
                  image={item.image.gatsbyImageData}
                />
              </div>
            )
          })}
        </div>

        <FunkyHr />
        <MostPopularKits
          i18n={i18n}
          products={data.suggestions.nodes}
          classes="mt-12"
        />
      </div>
    </>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!, $lang: String) {
    productI18n: allProductI18N(
      filter: { locale: { eq: $lang }, productId: { eq: $id } }
    ) {
      nodes {
        description
        descriptionHtml
      }
    }
    product: shopifyProduct(id: { eq: $id }) {
      title
      productType
      handle
      # productTypeSlug: gatsbyPath(
      #   filePath: "/products/{ShopifyProduct.productType}"
      # )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      media {
        ... on ShopifyMediaImage {
          image {
            altText
            gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
          }
        }
      }
      variants {
        availableForSale
        compareAtPrice
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        shopifyId
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
