import * as React from "react"
import { StoreContext } from "../context/store-context"

export function AddToCart({
  variantId,
  quantity,
  available,
  i18n,
  className,
  ...props
}) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <button
      type="submit"
      className={`${className} ${
        available ? "hover:bg-primary-500 bg-primary-600" : "bg-gray-500"
      } text-lg transition text-white rounded py-2 px-5 font-bold flex items-center justify-center`}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? i18n.common.addToCart : i18n.common.outOfStock}
    </button>
  )
}
