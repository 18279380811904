import React from "react"

interface CheckoutButtonProps {
  loading: boolean
  className?: string
  checkout: any
  i18n: any
}

export function CheckoutButton({
  checkout,
  loading,
  i18n,
  className,
}: CheckoutButtonProps) {
  const handleCheckout = () => {
    // https://slacklinemtl.myshopify.com/56842879150/checkouts/5fa631c96a8776d448609d816bfce5f2?key=1b909a1b05f1386d1b7fe10d16b7df5e
    window.open(checkout.webUrl)
  }
  return (
    <button
      onClick={handleCheckout}
      disabled={loading}
      className={`${className} max-w-sm mx-auto font-bold text-center text-white transition-colors rounded hover:bg-primary-500 bg-primary-600`}
    >
      {i18n.common.checkout}
    </button>
  )
}
